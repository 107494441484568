import React, { useCallback } from 'react';
import PageContainer from '../../Components/PageContainer';
import Text from '../../Components/Text';
import Input from '../../Components/Input';
import Button from '../../Components/Button';
import contact from '../../services/contact';

const Contact = () => {

    const handleSubmit = useCallback((e) => {
        e.preventDefault()
        contact(e.target.name.value, e.target.email.value, e.target.message.value)
    }, [])

    return (
        <PageContainer>
            <Text.H4 colored>Let's Connect!</Text.H4>
            <Text.P>There is no question too big or small. Let us know what’s on your mind and we will get back to you.</Text.P>
            <Text.P>ContactUs@blackcatdigital.us</Text.P>
            <form onSubmit={handleSubmit}>
                <Input type='text' placeholder='Name' name='name' required />
                <Input type='text' placeholder='Email Address' name='email' required />
                <Input.Multiline type='text' placeholder='Comments, Questions, Favorite Movies?' rows={6} name='message' required />
                <Button.Outlined>Submit</Button.Outlined>
            </form>
        </PageContainer>
    )
}

export default Contact;