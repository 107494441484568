import Text from '../Text';
import './footer.css';

const Footer = () => (
    <footer>
        <Text.P>Black Cat Digital</Text.P>
        <Text.P>ContactUs@blackcatdigital.us</Text.P>
        <Text.P>Copyright © 2021</Text.P>
    </footer>
)

export default Footer;