import { About, Contact, Home, Service, Services, Work, Works } from "./Pages";
import { Switch, Route, useLocation } from "react-router-dom";
import { useLayoutEffect } from "react";
const Routes = () => {
    const location = useLocation();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <Switch>
            <Route path='/work'>
                <Works />
            </Route>
            <Route path='/work/:projectID'>
                <Work />
            </Route>
            <Route path='/services/:serviceID'>
                <Service />
            </Route>
            <Route path='/services'>
                <Services />
            </Route>
            <Route path='/about'>
                <About />
            </Route>
            <Route path='/contact'>
                <Contact />
            </Route>
            <Route path='/'>
                <Home />
            </Route>
        </Switch>
)
}

export default Routes;