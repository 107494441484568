import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';

import { ReactComponent as Hamburger } from '../../icons/Hamburger.svg';
import { ReactComponent as Logo } from '../../icons/Logo.svg';
import TypeText from '../TypeText';
import Menu from '../Menu';
import Text from '../Text';
import './header.css';

const Header = () => {
    const [headerTitle, setHeaderTitle] = useState('');
    const [menuOpen, setMenuOpen] = useState(false);

    const locationTitleMap = useRef({
        '/services': 'Services',
        '/contact': 'Contact',
        '/about': 'About',
        '/work': 'Work',
        '/': 'Home',
    }).current

    const navItems = useRef({
        'Home': '/',
        'Services': '/services',
        'About': '/about',
        'Work': '/work',
        'Contact': '/contact'
    }).current

    const location = useLocation();

    const toggleMenu = useCallback(() => {
        setMenuOpen(!menuOpen);
    }, [menuOpen])

    useEffect(() => {
        Object.entries(locationTitleMap).forEach(([routeMatch, title]) => {
            if (!!matchPath(location.pathname, {
                path: routeMatch,
                exact: true,
                strict: true
            })) {
                setHeaderTitle(title);
            }
        })
    }, [location, locationTitleMap])
    
    return (
        <div id='header'>
            <Link to='/'>
                <div id='logo-container'>
                    <Logo id='header-logo' />
                </div>
            </Link>
            <div id='spacer'/>
            <Text.H6 id='header-title'>
                <TypeText text={headerTitle} />
            </Text.H6>
            <div id='spacer'/>
            <Hamburger id='header-hamburger' onClick={toggleMenu} />
            {
                <nav id='desktop-nav'>
                    {
                        Object.entries(navItems).map(([name, route]) => (
                            <Link to={route}>
                                <div className={`link-container${headerTitle === name ? ' selected' : ''}`}>
                                    <Text.P style={{fontWeight: 400}}>
                                        {name}
                                    </Text.P>
                                </div>
                            </Link>
                        ))
                    }
                </nav>
            }
            <Menu isOpen={menuOpen} closeMenu={toggleMenu} />
        </div>
    )
}

export default Header;