import React from 'react';
import './layout.css';

const Layout = {};

Layout.Row = ({ children }) => (
    <div className='flex row'>
        {children}
    </div>
);

Layout.Column = ({ children }) => (
    <div className='flex column'>
        {children}
    </div>
);

export default Layout;