import React, { useEffect, useState } from 'react';

const TypeText = ({ text, callback }) => {
    const [displayText, setDisplayText] = useState('');
    const [cursorShowing, setCursorShowing] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (displayText.length !== text.length && text.length) {
                setDisplayText(displayText + text[displayText.length])
            } else {
                callback?.()
            }
        }, Math.floor(Math.random() * (175 - 50 + 1) + 50))
        return () => {
            clearTimeout(timeout);
        }
    }, [displayText, callback, text])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setCursorShowing(!cursorShowing);
        }, 500);
        return () => {
            clearTimeout(timeout)
        }
    }, [cursorShowing])

    useEffect(() => {
        if (text.length) {
            setDisplayText(text[0]);
        } else {
            setDisplayText('');
        }
    }, [text])

    return (
        <>
            {displayText}<span style={{color: (cursorShowing && text.length) ? '#DFFF00' : '#00000000'}}>|</span>
        </>
    )
}

export default TypeText;