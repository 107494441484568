import React from 'react';
import './pageContainer.css';

const PageContainer = ({children}) => (
    <div className='outer-page-container'>
        <div className='page-container'>
            {children}
        </div>
    </div>
)

export default PageContainer