import './input.css';

const Input = props => {
    return (
        <input className='input' {...props} autoComplete='none' />
    )
}

Input.Multiline = props => {
    return (
        <textarea className='input' {...props} autoComplete='none'/>
    )
}

export default Input