import React from 'react';
import Text from '../Text';
import './button.css';

const Button = {};

Button.Outlined = ({children, ...props}) => (
    <button className='outlined' {...props}>
        <Text.P className='block'>
            {children}
        </Text.P>
    </button>
)

Button.Filled = ({ children, ...props }) => (
    <button className='filled' {...props}>
        <Text.P className='block'>
            {children}
        </Text.P>
    </button>
)

export default Button;