import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../Button';
import './navButton.css';

const NavButton = ({ to, children, ...props }) => {
    
    const history = useHistory();

    const handleNavigate = useCallback((e) => {
        e.preventDefault();
        history.push(to);
    }, [history, to]);

    return (
        <a href={to} onClick={handleNavigate} {...props}>
            <Button.Outlined>
                {children}
            </Button.Outlined>
        </a>
    )
};

export default NavButton;