import React, { useMemo, useRef, useState, useCallback, useEffect } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router';

import './menu.css';

const Menu = ({ isOpen, closeMenu }) => {
    const [selectedLocation, setSelectedLocation] = useState('/')
    const history = useHistory()

    const handleNavItemPress = (route) => () => {
        setTimeout(() => {
            history.push(route);
            closeMenu();
        }, 350);
    }

    const top = useMemo(() => (
        isOpen ? 'translate3d(0, 0px, 0)' : `translate3d(0, -${window.screen.height}px, 0)`
    ), [isOpen])
    
    const isRouteSelected = useCallback((route) => {
        return route === selectedLocation;
    }, [selectedLocation])

    const getColorForNavItem = useCallback((route) => {
        if (isRouteSelected(route)) {
            return '#DFFF11'
        }
        return '#FFFFFF'
    }, [isRouteSelected])

    const handleKey = useCallback((key) => {
        if (key.key === 'Escape') {
            closeMenu()
        }
    }, [closeMenu])

    const locationTitleMap = useRef([
        '/services',
        '/contact',
        '/about',
        '/work',
        '/'
    ]).current

    const location = useLocation();

    useEffect(() => {
        locationTitleMap.forEach(route => {
            if (!!matchPath(location.pathname, {
                path: route,
                exact: true,
                strict: true
            })) {
                setSelectedLocation(route);
            }
        })
    }, [location, setSelectedLocation, locationTitleMap])

    useEffect(() => {
        if (isOpen) {
            window.addEventListener('keydown', handleKey);
            return () => {
                window.removeEventListener('keydown', handleKey);
            }
        }
        window.removeEventListener('keydown', handleKey);
    }, [isOpen, handleKey])

    return (
        <div id='menu' style={{ transform: top, WebkitTransform: top }}>
            <div>
                <h3 style={{ color: getColorForNavItem('/') }} onClick={handleNavItemPress('/')}>Home</h3>
                <h3 style={{ color: getColorForNavItem('/work') }}  onClick={handleNavItemPress('/work')}>Work</h3>
                <h3 style={{ color: getColorForNavItem('/services') }}  onClick={handleNavItemPress('/services')}>Services</h3>
                <h3 style={{ color: getColorForNavItem('/about') }}  onClick={handleNavItemPress('/about')}>About</h3>
                <h3 style={{ color: getColorForNavItem('/contact') }}  onClick={handleNavItemPress('/contact')}>Contact</h3>
            </div>
        </div>
    )
}

export default Menu;