import React, { useCallback, useMemo, useState } from 'react';
import PageContainer from '../../Components/PageContainer';
import Text from '../../Components/Text';
import './services.css';

const Service = ({title, img, text}) => {
    const [isOpen, setIsOpen] = useState(false)

    const contentID = useMemo(() => {
        return `service-${title}`
    }, [title])

    const handleServiceClick = useCallback(() => {
        const serviceContet = document.getElementById(contentID)

        if (!isOpen) {
            setTimeout(() => {
                serviceContet.scrollIntoViewIfNeeded()
            }, 500)
        }

        setIsOpen(!isOpen)
    }, [contentID, isOpen, setIsOpen])

    return (
        <>
            <div style={{display: 'flex'}} onClick={handleServiceClick} className={`service-container${isOpen ? ' open' : ''}`}>
                <Text.H5 style={{marginRight: 30, lineHeight: '65px', verticalAlign: 'center'}}>{title}</Text.H5>
                <div style={{flex: 1}} />
                <Text.H5 colored style={{lineHeight: '65px'}}>{!isOpen ? '+' : '-'}</Text.H5>
            </div>
            <div id={contentID} className={`service-content${isOpen ? ' open' : ''}`}>
                <div style={{width: '100%', height: 200}}>
                <img src={img} style={{width: '100%', height: '100%', objectFit: 'cover'}} alt={`${title}-header`} />
                </div>
                <Text.P>{text}</Text.P>
            </div>
        </>
    )
}

const Services = () => {

    const [services] = useState([{
        title: "Development",
        img: './development.jpg',
        alt: 'Code on a screen',
        text: 'We have a unique wealth of experience in the field of development ranging in unique industries from aerospace to e-commerce, and more. Our team is skilled at building custom websites and applications that will not only satisfy but surpass your businesses needs. '
    },{
        title: "UX Design / Research",
        img: './ux.jfif',
        alt: 'People doing design research on a whiteboard',
        text: 'User Experience research and design is a special point of interest of ours. We can provide user research, usability testing, user first design and usability evaluations to ensure that your product is optimized for your customers. '
    },{
        title: "Visual Design & Branding",
        img: './branding.jfif',
        alt: 'Brand style guides layed out on a table',
        text: 'With combined experiences in graphic design, our team is positioned to provide you with unique and memorable branding. From logos, to brand guidelines, to social media templates, website design, print design, and any other thing you can think of. If you need it, we’ll design it. '
    },{
        title: "Coming Soon: Social Media Management",
        img: './marketing.jfif',
        alt: 'User statistics dashboard',
        text: `We know that posting daily can be a major headache, and maintaining your brand via social media can feel like a daunting task when you're running a business. We offer social media management services, providing posting services and guidance.`
    }]);

    return (
        <PageContainer>
            {
                services.map(service => <Service {...service} />)
            }
        </PageContainer>
    )
}

export default Services;