// import React, { useCallback, useMemo, useState } from 'react';

// import CardContainer from '../../Components/CardContainer';
import PageContainer from '../../Components/PageContainer';
import Text from '../../Components/Text';
import './works.css';

const Work = () => {
    // const [work] = useState([{
    //     title: "E-Commerce Experience That Educates While Selling",
    //     tags: ["design", "UX", "development"],
    //     img: './development.jpg',
    // },{
    //     title: "Cynthia's Rocks, Making a Hard Website",
    //     tags: ["development"],
    //     img: './development.jpg',
    // },{
    //     title: "We Did Some Other Thing. It had to do with marketing",
    //     tags: ["marketing"],
    //     img: './development.jpg',
    // }]);
    
    // const [selectedFilter, setSelectedFilter] = useState(0);
    
    // const filters = useMemo(() => (
    //     Object.keys(work.reduce((filters, project) => ({
    //         ...filters,
    //         ...project.tags.reduce((tags, tag) => ({
    //             ...tags,
    //             [tag]: true
    //         }), {})
    //     }), {all: true}))
    // ), [work]);

    // const shownWork = useMemo(() => (
    //     selectedFilter === 0
    //         ? work
    //         : work.reduce((filteredWork, project) => (
    //             !project.tags.includes(filters[selectedFilter])
    //                 ? filteredWork
    //                 : [...filteredWork, project]
    //         ), [])
    // ), [selectedFilter, work, filters]);

    // const handleNavClick = useCallback((filterIndex) => () => {
    //     setSelectedFilter(filterIndex)
    // }, [setSelectedFilter]);

    // const getClassName = useCallback((filterIndex) => (
    //     selectedFilter === filterIndex ? 'selected' : ''
    // ), [selectedFilter]);

    return (
        <PageContainer>
            <div id='coming-soon-container'>
                <Text.H3>Coming Soon...</Text.H3>
            </div>
            {/* <div id='work-nav'>
                {filters.map((filter, i) => (
                    <p
                        key={`filter-${filter}`}
                        onClick={handleNavClick(i)}
                        className={getClassName(i)}
                    >
                        {filter}
                    </p>
                ))}
            </div>
            <CardContainer>
                {shownWork.map(project => <CardContainer.Card {...project} />)}
            </CardContainer> */}
        </PageContainer>
    )
}

export default Work;