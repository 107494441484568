import React from 'react';
import { useParams } from 'react-router';
import PageContainer from '../../Components/PageContainer';

const Service = () => {
    const params = useParams()
    console.log(params)
    return (
        <PageContainer>
            <h3>{params.serviceID}</h3>
        </PageContainer>
    )
}

export default Service;