
const contact = async (name, email, message) => {
    try {
        fetch('https://us-central1-envelope-6875e.cloudfunctions.net/envelope/pdZL6K64oAXVtVYOZwgb', {
            method: 'post',
            body: {
                name,
                email,
                message
            }
        })
    } catch (err) {
        console.log(err)
    }
}

export default contact