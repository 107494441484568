import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../Components/Button';
import Layout from '../../Components/Layout';
import NavButton from '../../Components/NavButton';
import Text from '../../Components/Text';
import TypeText from '../../Components/TypeText';
import './home.css';

const Home = () => {
    
    const headerText = useRef(`black cat
digital`).current;

    return (
        <div id='home-container'>
            <div className='cta-container'>
                <Layout.Row>
                    <Text.H2 multiLine={2} capitalized>
                        <TypeText text={headerText} />
                    </Text.H2>
                </Layout.Row>
                <Text.P>We solve your modern day business branding and digital presence problems for you.</Text.P>
                <NavButton to='/contact' id='connect-button'>
                    Let's Connect
                </NavButton>
            </div>
            <div id='map'>
                <div className='cta-container'>
                    <Text.H4 id='map-header' style={{alignSelf: 'flex-end'}} className='bold color-text'>Put Your<br/>Business On<br/>The Map.</Text.H4>
                    <Text.P >Build your brand, establish an online identity and market your unique services to the world.</Text.P>
                    <Link to='work'>
                        <Button.Outlined>See Work</Button.Outlined>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Home;