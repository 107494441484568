import React from 'react';
import './text.css';

const Text = {};

const BaseText = ({ TextComponent, children, multiLine, style, className = '', alt, colored, capitalized, boxed, id }) => {
    return !boxed ? (<>
        <TextComponent style={style} id={id} className={`${colored ? 'color-text ' : ''}${alt ? 'alt ' : ''}${className}${capitalized ? 'capitalized ' : ''}`}>
            {children}
        </TextComponent>
        {multiLine && (
            <TextComponent className='multiline-spacing-text'>
                {new Array(multiLine).fill(null).map(() => <br />)}
            </TextComponent>
        )}
    </>) : (<div className='text-box'>
    <TextComponent style={style} id={id} className={`${colored ? 'color-text ' : ''}${alt ? 'alt ' : ''}${className}${capitalized ? 'capitalized ' : ''}`}>
        {children}
    </TextComponent>
    {multiLine && (
        <TextComponent className='multiline-spacing-text'>
            {new Array(multiLine).fill(null).map(() => <br />)}
        </TextComponent>
    )}
</div>)
}

const wrapBaseText = wrapper => props => <BaseText TextComponent={wrapper} {...props} />

Text.H1 = wrapBaseText(({ children, className, style, colored, id }) => <h1 id={id} className={className} style={style} colored={colored}>{children}</h1>)
Text.H2 = wrapBaseText(({ children, className, style, colored, id }) => <h2 id={id} className={className} style={style} colored={colored}>{children}</h2>)
Text.H3 = wrapBaseText(({ children, className, style, colored, id }) => <h3 id={id} className={className} style={style} colored={colored}>{children}</h3>)
Text.H4 = wrapBaseText(({ children, className, style, colored, id }) => <h4 id={id} className={className} style={style} colored={colored}>{children}</h4>)
Text.H5 = wrapBaseText(({ children, className, style, colored, id }) => <h5 id={id} className={className} style={style} colored={colored}>{children}</h5>)
Text.H6 = wrapBaseText(({ children, className, style, colored, id }) => <h6 id={id} className={className} style={style} colored={colored}>{children}</h6>)
Text.P = wrapBaseText(({ children, className, style, colored, id }) => <p id={id} className={className} style={style} colored={colored}>{children}</p>)

export default Text;