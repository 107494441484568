import React from 'react';
import PageContainer from '../../Components/PageContainer';
import NavButton from '../../Components/NavButton';
import './about.css';

const About = () => {
    return (
        <PageContainer>
            <h3>Black cats have an interesting reputation.</h3>
            <div id='about-container'>
                <p>
                    Whether they are seen as bad luck, or as mystical creatures with the power of speech, one thing about them is for sure, <span className='color-text bold'>they are unique, and they stand out from the rest.</span>
                </p>
                <p>
                The owners of Black Cat Consulting adopted a funny little black cat in 2017. She wasn’t like the other kittens. She was hyper focused on using her thumb sized paws to cajole a toy out from behind a couch cushion. She didn’t want to be pet or held or touched, she only wanted to complete the task she had set her little mind to do. And we liked that.
                </p>
                <p>
                Our business is built on the idea that when you are passionate and motivated the achieve something, you can. We don’t believe in rigid or by the book methods. <span className='color-text bold'>We do things different and we do them well. It’s as simple as that.</span>
                </p>
                <p>
                So what do we offer you? To keep it frank, we are a no BS company. We want to work with you, and we want to help. <span className='color-text bold'>There is no issue too big or small</span> for us to take on. Feel free to reach here with any questions, we’re looking forward to working together.
                </p>
            </div>
            <NavButton to='/contact'>Let's Connect</NavButton>
        </PageContainer>
    )
}

export default About;